import { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";

const advancedMatching = null;
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false // enable logs
};

function useFBPixel(id, route) {
  useEffect(() => {
    ReactPixel.init(id, advancedMatching, options);
    ReactPixel.pageView(route);
  }, []);
}

export default useFBPixel;
