import React, {
  useReducer,
  useRef,
  useCallback,
  useEffect,
  useState
} from "react";
import styled, { keyframes } from "styled-components";
import useMouse from "react-use/lib/useMouse";
import ga from "react-ga";

import {
  ADD_APP,
  DEL_APP,
  FOCUS_APP,
  MINIMIZE_APP,
  TOGGLE_MAXIMIZE_APP,
  FOCUS_ICON,
  SELECT_ICONS,
  FOCUS_DESKTOP,
  START_SELECT,
  END_SELECT,
  POWER_OFF,
  CANCEL_POWER_OFF
} from "./constants/actions";
import { FOCUSING, POWER_STATE } from "./constants";
import { defaultIconState, defaultAppState, appSettings } from "./apps";
// import Modal from "./Modal";
import Footer from "./Footer";
import Windows from "./Windows";
import Icons from "./Icons";
import { DashedBox } from "../components";
import { Wallpapers } from "./themes/icons";

const initState = {
  apps: defaultAppState,
  nextAppID: defaultAppState.length,
  nextZIndex: defaultAppState.length,
  focusing: FOCUSING.WINDOW,
  icons: defaultIconState,
  selecting: false,
  powerState: POWER_STATE.START
  // wallpaper: awExampleWallpaper,
};
const reducer = (state, action = { type: "" }) => {
  // ga.event({
  //   category: "Window interaction",
  //   action: action.type
  // });
  switch (action.type) {
    case ADD_APP:
      const app = state.apps.find(
        _app => _app.component === action.payload.component
      );
      if (action.payload.multiInstance || !app) {
        return {
          ...state,
          apps: [
            ...state.apps,
            {
              ...action.payload,
              id: state.nextAppID,
              zIndex: state.nextZIndex
            }
          ],
          nextAppID: state.nextAppID + 1,
          nextZIndex: state.nextZIndex + 1,
          focusing: FOCUSING.WINDOW
        };
      }
      const apps = state.apps.map(app =>
        app.component === action.payload.component
          ? { ...app, zIndex: state.nextZIndex, minimized: false }
          : app
      );
      return {
        ...state,
        apps,
        nextZIndex: state.nextZIndex + 1,
        focusing: FOCUSING.WINDOW
      };
    case DEL_APP:
      return {
        ...state,
        apps: state.apps.filter(app => app.id !== action.payload),
        focusing:
          state.apps.length > 1
            ? FOCUSING.WINDOW
            : state.icons.find(icon => icon.isFocus)
            ? FOCUSING.ICON
            : FOCUSING.DESKTOP
      };
    case FOCUS_APP: {
      const apps = state.apps.map(app =>
        app.id === action.payload
          ? { ...app, zIndex: state.nextZIndex, minimized: false }
          : app
      );
      return {
        ...state,
        apps,
        nextZIndex: state.nextZIndex + 1,
        focusing: FOCUSING.WINDOW
      };
    }
    case MINIMIZE_APP: {
      const apps = state.apps.map(app =>
        app.id === action.payload ? { ...app, minimized: true } : app
      );
      return {
        ...state,
        apps,
        focusing: FOCUSING.WINDOW
      };
    }
    case TOGGLE_MAXIMIZE_APP: {
      const apps = state.apps.map(app =>
        app.id === action.payload ? { ...app, maximized: !app.maximized } : app
      );
      return {
        ...state,
        apps,
        focusing: FOCUSING.WINDOW
      };
    }
    case FOCUS_ICON: {
      const icons = state.icons.map(icon => {
        if (icon.id === action.payload)
          return {
            ...icon,
            isFocus: true
          };
        else
          return {
            ...icon,
            isFocus: false
          };
      });
      return {
        ...state,
        focusing: FOCUSING.ICON,
        icons
      };
    }
    case SELECT_ICONS: {
      const icons = state.icons.map(icon => ({
        ...icon,
        isFocus: action.payload.includes(icon.id)
      }));
      return {
        ...state,
        icons,
        focusing: FOCUSING.ICON
      };
    }
    case FOCUS_DESKTOP:
      return {
        ...state,
        focusing: FOCUSING.DESKTOP,
        icons: state.icons.map(icon => ({
          ...icon,
          isFocus: false
        }))
      };
    case START_SELECT:
      return {
        ...state,
        focusing: FOCUSING.DESKTOP,
        icons: state.icons.map(icon => ({
          ...icon,
          isFocus: false
        })),
        selecting: action.payload
      };
    case END_SELECT:
      return {
        ...state,
        selecting: null
      };
    case POWER_OFF:
      return {
        ...state,
        powerState: action.payload
      };
    case CANCEL_POWER_OFF:
      return {
        ...state,
        powerState: POWER_STATE.START
      };
    default:
      return state;
  }
};

const ConnectionOverlay = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100000;
`;

const spinnerKeyframes = keyframes`
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
`;

const secondarySpinnerKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingSpinner = styled.div`
  color: #ffffff;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  transform: translateZ(0);
  animation: ${spinnerKeyframes} 1.7s infinite ease,
    ${secondarySpinnerKeyframes} 1.7s infinite ease;
`;

const WinXP = ({ activeUser, content, isLoading, logOut }) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const ref = useRef(null);
  const mouse = useMouse(ref);
  const focusedAppId = getFocusedAppId();

  useEffect(() => {
    if (!isLoading && activeUser) {
      setTimeout(() => {
        dispatch({
          type: ADD_APP,
          payload: {
            ...appSettings.Chat,
            injectProps: { content, isLoading, activeUser }
          }
        });
        setTimeout(() => {
          setFullyLoaded(true);
        }, 990);
      }, 400);
    }
  }, [isLoading]);

  const onFocusApp = useCallback(
    id => {
      dispatch({ type: FOCUS_APP, payload: id });
    },
    [focusedAppId]
  );
  const onMaximizeWindow = useCallback(
    id => {
      if (focusedAppId === id && state.focusing === FOCUSING.WINDOW) {
        dispatch({ type: TOGGLE_MAXIMIZE_APP, payload: id });
      }
    },
    [focusedAppId]
  );
  const onMinimizeWindow = useCallback(
    id => {
      if (focusedAppId === id && state.focusing === FOCUSING.WINDOW) {
        dispatch({ type: MINIMIZE_APP, payload: id });
      }
    },
    [focusedAppId]
  );
  const onCloseApp = useCallback(
    id => {
      if (focusedAppId === id && state.focusing === FOCUSING.WINDOW) {
        dispatch({ type: DEL_APP, payload: id });
      }
    },
    [focusedAppId]
  );
  function onMouseDownFooterApp(id) {
    if (focusedAppId === id) {
      dispatch({ type: MINIMIZE_APP, payload: id });
    } else {
      dispatch({ type: FOCUS_APP, payload: id });
    }
  }
  function onMouseDownIcon(id) {
    dispatch({ type: FOCUS_ICON, payload: id });
  }
  function onDoubleClickIcon(component) {
    const appSetting = Object.values(appSettings).find(
      setting => setting.component === component
    );
    if (
      appSetting.header &&
      (appSetting.header.title === "Chat" ||
        appSetting.header.title === "My Pictures" ||
        appSetting.header.title === "Video Library")
    ) {
      appSetting.injectProps = { content, activeUser, isLoading };
    }
    if (appSetting.header && appSetting.header.title === "Notes") {
      dispatch({
        type: ADD_APP,
        payload: {
          ...appSettings.Error,
          injectProps: { message: "Not Authorized" }
        }
      });
      return;
    }
    dispatch({ type: ADD_APP, payload: appSetting });
  }
  function getFocusedAppId() {
    const focusedApp = [...state.apps]
      .sort((a, b) => b.zIndex - a.zIndex)
      .find(app => !app.minimized);
    return focusedApp ? focusedApp.id : -1;
  }
  function onMouseDownFooter() {
    dispatch({ type: FOCUS_DESKTOP });
  }
  function onClickMenuItem(o) {
    switch (o) {
      case "Chat":
        dispatch({
          type: ADD_APP,
          payload: {
            ...appSettings.Chat,
            injectProps: { content, isLoading, activeUser }
          }
        });
        break;
      // case "FL Studio":
      //   dispatch({
      //     type: ADD_APP,
      //     payload: {
      //       ...appSettings["FL Studio Sessions"],
      //       injectProps: {
      //         content,
      //         isLoading,
      //         activeUser
      //       }
      //     }
      //   });
      //   break;
      case "My Pictures":
        dispatch({
          type: ADD_APP,
          payload: {
            ...appSettings["My Pictures"],
            injectProps: { content, isLoading, activeUser }
          }
        });
        break;
      case "Video Library":
        dispatch({
          type: ADD_APP,
          payload: {
            ...appSettings["Video Library"],
            injectProps: { content, isLoading, activeUser }
          }
        });
        break;
      // case "Video Library":
      //   dispatch({ type: ADD_APP, payload: appSettings["Video Library"] });
      //   break;
      // case "Notes":
      //   dispatch({ type: ADD_APP, payload: appSettings["Notes"] });
      //   break;
      case "Log Off":
        logOut();
        break;
      default:
        dispatch({
          type: ADD_APP,
          payload: {
            ...appSettings.Error,
            injectProps: { message: "Not Authorized" }
          }
        });
        break;
    }
  }
  function onMouseDownDesktop(e) {
    if (e.target === e.currentTarget)
      dispatch({
        type: START_SELECT,
        payload: { x: mouse.docX, y: mouse.docY }
      });
  }
  function onMouseUpDesktop(e) {
    dispatch({ type: END_SELECT });
  }
  function onIconsSelected(iconIds) {
    dispatch({ type: SELECT_ICONS, payload: iconIds });
  }
  function onClickModalButton(text) {
    dispatch({ type: CANCEL_POWER_OFF });
    dispatch({
      type: ADD_APP,
      payload: appSettings.Error
    });
  }
  function onModalClose() {
    dispatch({ type: CANCEL_POWER_OFF });
  }
  return (
    <Container
      ref={ref}
      onMouseUp={onMouseUpDesktop}
      onMouseDown={onMouseDownDesktop}
      state={state.powerState}
      activeUser={activeUser}
    >
      {!fullyLoaded && (
        <ConnectionOverlay>
          <LoadingSpinner />
          Establishing Screen Sharing Connection...
        </ConnectionOverlay>
      )}
      <Icons
        icons={state.icons}
        onMouseDown={onMouseDownIcon}
        onDoubleClick={onDoubleClickIcon}
        displayFocus={state.focusing === FOCUSING.ICON}
        appSettings={appSettings}
        mouse={mouse}
        selecting={state.selecting}
        setSelectedIcons={onIconsSelected}
      />
      <DashedBox startPos={state.selecting} mouse={mouse} />
      <Windows
        apps={state.apps}
        onMouseDown={onFocusApp}
        onClose={onCloseApp}
        onMinimize={onMinimizeWindow}
        onMaximize={onMaximizeWindow}
        focusedAppId={focusedAppId}
        activeUser={activeUser}
      />
      <Footer
        apps={state.apps}
        onMouseDownApp={onMouseDownFooterApp}
        focusedAppId={focusedAppId}
        onMouseDown={onMouseDownFooter}
        onClickMenuItem={onClickMenuItem}
        activeUser={activeUser}
      />
      {/* {state.powerState !== POWER_STATE.START && (
        <Modal
          onClose={onModalClose}
          onClickButton={onClickModalButton}
          mode={state.powerState}
        />
      )} */}
    </Container>
  );
};

const powerOffAnimation = keyframes`
  0% {
    filter: brightness(1) grayscale(0);
  }
  30% {
    filter: brightness(1) grayscale(0);
  }
  100% {
    filter: brightness(0.6) grayscale(1);
  }
`;
const animation = {
  [POWER_STATE.START]: "",
  [POWER_STATE.TURN_OFF]: powerOffAnimation,
  [POWER_STATE.LOG_OFF]: powerOffAnimation
};

const Container = styled.div`
  @import url("https://fonts.googleapis.com/css?family=Noto+Sans");
  font-family: "Open Sans";
  height: 100%;
  overflow: hidden;
  position: relative;
  background-image: url(${props => Wallpapers[props.activeUser]});
  background-repeat: no-repeat;
  background-position: bottom center;
  /* background-color: red; */
  background-size: cover;
  /* animation: ${({ state }) => animation[state]} 5s forwards; */
  *:not(input):not(textarea) {
    user-select: none;
  }
`;

export default WinXP;
