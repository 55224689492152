import React, { useState } from "react";
import styled from "styled-components";

import { AppIcons, TrayIcons } from "../themes/icons";

import { ARTIST, getArtistName } from "../constants/profiles";
import Colors from "../themes/colors";
import { Profiles } from "../themes/icons";

function FooterMenu({ className, onClick, activeUser }) {
  const [hovering, setHovering] = useState("");
  function onMouseOver(e) {
    const item = e.target.closest(".menu__item");
    if (!item) return;
    setHovering(item.querySelector(".menu__item__text").textContent);
  }
  return (
    <div className={className}>
      <header>
        <img className="header__img" src={Profiles[activeUser]} alt="avatar" />
        <span className="header__text">
          {getArtistName(activeUser)}&apos;s Laptop
        </span>
      </header>
      <section className="menu" onMouseOver={onMouseOver}>
        <div className="menu__left">
          <Item onClick={onClick} text="Chat" icon={AppIcons.CHAT}>
            <div className="menu__item__subtext">Contacts &amp; Chat</div>
          </Item>
          <Item onClick={onClick} text="Video Library" icon={AppIcons.VIDEO}>
            <div className="menu__item__subtext">View Video Files</div>
          </Item>
          <div className="menu__separator" />
          <Items
            onClick={onClick}
            items={[
              { icon: AppIcons.IMAGES, text: "My Pictures" },
              { icon: AppIcons.NOTES, text: "Notes" }
            ]}
          />
          <div style={{ flex: 1 }} />
        </div>
      </section>
      <footer>
        <div
          className="footer__item"
          onClick={() => onClick("Log Off")}
        >
          <img className="footer__item__img" src={TrayIcons.LOGOUT} alt="" />
          <span>Log Out</span>
        </div>
      </footer>
    </div>
  );
}
function Items({ items, ...rest }) {
  return items.map((item, i) => <Item key={i} {...item} {...rest} />);
}
function Item({
  style,
  text,
  icon,
  onHover = () => {},
  onClick = () => {},
  children
}) {
  function _onClick() {
    onClick(text);
  }
  function onMouseEnter() {
    onHover(text);
  }
  return (
    <div
      className="menu__item"
      style={style}
      onClick={_onClick}
      onMouseEnter={onMouseEnter}
    >
      <img className="menu__item__img" src={icon} alt={text} />
      <div className="menu__item__texts">
        <div className="menu__item__text ">{text}</div>
        {children}
      </div>
    </div>
  );
}
export default styled(FooterMenu)`
  font-size: 11px;
  line-height: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => Colors[props.activeUser].Dark}F2;
  /* border-top-left-radius: 5px;
  border-top-right-radius: 5px; */
  header {
    position: relative;
    align-self: flex-start;
    display: flex;
    align-items: center;
    color: #fff;
    height: 54px;
    /* padding: 6px 5px 5px; */
    width: 100%;
    /* border-top-left-radius: 5px;
    border-top-right-radius: 5px; */
    background: ${props => Colors[props.activeUser].Black}F2;
    overflow: hidden;
  }
  header:before {
    content: "";
    display: block;
    position: absolute;
    top: 1px;
    left: 0;
    width: 100%;
    height: 3px;
  }
  .header__img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin-left: 10px;
  }
  .header__text {
    font-size: 14px;
    font-weight: 800;
    margin-left: 10px;
  }
  footer {
    display: flex;
    align-self: flex-end;
    align-items: center;
    justify-content: flex-end;
    color: #fff;
    height: 36px;
    width: 100%;
    background: ${props => Colors[props.activeUser].Light};
  }

  .footer__item {
    /* padding: 10px 3px; */
    display: flex;
    margin-right: 10px;
    /* margin-top: 10px;
    margin-bottom: 10px; */
    align-items: center;
    cursor: pointer;
    padding: 2px 20px 2px 12px;
    background-color: ${props => Colors[props.activeUser].Black};
    &:hover {
      background-color: ${props => Colors[props.activeUser].Highlight};
    }
  }
  .footer__item__img {
    margin-right: 12px;
    width: 22px;
    height: 22px;
  }
  .menu {
    display: flex;
    /* margin: 0 2px; */
    position: relative;
    border-top: 2px solid ${props => Colors[props.activeUser].Light};
  }
  .menu__left {
    /* background-color: */
    /* padding: 6px 5px 0; */
    width: 320px;
    display: flex;
    flex-direction: column;
  }
  .sub_menu {
    border: 1px solid black;
    position: absolute;
    left: 100%;
    bottom: 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }

  .menu__separator {
    border-top: 2px solid ${props => Colors[props.activeUser].Main};
  }
  .menu__item {
    padding: 1px;
    display: flex;
    align-items: center;
    height: 50px;
    color: white;
  }
  .menu__item:hover {
    color: white;
    background-color: ${props => Colors[props.activeUser].Main}F2;
  }
  .menu__item:hover .menu__item__subtext {
    color: white;
  }
  .menu__item__texts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    position: relative;
    margin-left: 10px;
  }
  .menu__left .menu__item__img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
  }
  .menu__left .menu__item:nth-child(-n + 2),
  .menu__left .menu__item:last-child {
    .menu__item__text {
      font-weight: 700;
    }
  }
  .menu__item__subtext {
    color: white;
    line-height: 12px;
    margin-bottom: 1px;
  }
  .menu__left .menu__item:last-child {
    height: 24px;
  }
  .menu__item:hover .menu__arrow {
    border-left-color: #fff;
  }
  .menu__arrow {
    border: 3.5px solid transparent;
    border-right: 0;
    border-left-color: #00136b;
    position: absolute;
    left: 146px;
  }
`;
