import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import WinXP from "../OS";
import Chat from "../OS/apps/PLAY/Chat";

const Preface = ({ content, activeUser, isLoading, logOut, history }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)"
  });

  useEffect(() => {
    if (activeUser == null) {
      history.push("/");
    }
  }, [activeUser]);

  return (
    activeUser &&
    (isDesktopOrLaptop ? (
      <WinXP
        content={content}
        activeUser={activeUser}
        isLoading={isLoading}
        logOut={logOut}
      />
    ) : (
      <Chat
        mobile
        content={content}
        activeUser={activeUser}
        isLoading={isLoading}
        logOut={logOut}
      />
    ))
  );
};

export default Preface;
