import { ARTIST } from "../constants/profiles";

const Colors = {
  // [ARTIST.AW]: {
  //   Highlight: "#4D6874",
  //   Light: "#354F5B",
  //   Main: "#203A45",
  //   Dark: "#132D38",
  //   Black: "#071C26"
  // },
  [ARTIST.AW]: {
    Highlight: "#47BBBB",
    Light: "#23AAAA",
    Main: "#00A3A3",
    Dark: "#007D7D",
    Black: "#006262"
  },
  [ARTIST.K3]: {
    Highlight: "#F4916F",
    Light: "#D46641",
    Main: "#B74720",
    Dark: "#962E0A",
    Black: "#6C1C00"
  },
  [ARTIST.TV]: {
    Highlight: "#E03737",
    Light: "#C01312",
    Main: "#A00100",
    Dark: "#7C0000",
    Black: "#510000"
  },
  Base: {
    Black: "#111"
  }
};

export default Colors;
