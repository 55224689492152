import { keyframes } from "styled-components";

const flicker = keyframes`
  0% {
    opacity: 0.06725;
  }
  5% {
    opacity: 0.08052;
  }
  10% {
    opacity: 0.10484;
  }
  15% {
    opacity: 0.26847;
  }
  20% {
    opacity: 0.94194;
  }
  25% {
    opacity: 0.8736;
  }
  30% {
    opacity: 0.64387;
  }
  35% {
    opacity: 0.13945;
  }
  40% {
    opacity: 0.84864;
  }
  45% {
    opacity: 0.79316;
  }
  50% {
    opacity: 0.5446;
  }
  55% {
    opacity: 0.86237;
  }
  60% {
    opacity: 0.36493;
  }
  65% {
    opacity: 0.82283;
  }
  70% {
    opacity: 0.02738;
  }
  75% {
    opacity: 0.20162;
  }
  80% {
    opacity: 0.95474;
  }
  85% {
    opacity: 0.76364;
  }
  90% {
    opacity: 0.35923;
  }
  95% {
    opacity: 0.12021;
  }
  100% {
    opacity: 0.35788;
  }
`;

const turnOn = keyframes`
0% {
    transform: scale(1, 0.8) translate3d(0, 0, 0);
    -webkit-filter: brightness(30);
    filter: brightness(30);
    opacity: 1;
  }
  3.5% {
    transform: scale(1, 0.8) translate3d(0, 100%, 0);
  }
  3.6% {
    transform: scale(1, 0.8) translate3d(0, -100%, 0);
    opacity: 1;
  }
  9% {
    transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
    -webkit-filter: brightness(30);
    filter: brightness(30);
    opacity: 0;
  }
  11% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-filter: contrast(0) brightness(0);
    filter: contrast(0) brightness(0);
    opacity: 0;
  }
  100% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-filter: contrast(1) brightness(1.2) saturate(1.3);
    filter: contrast(1) brightness(1.2) saturate(1.3);
    opacity: 1;
  }
`;

const turnOff = keyframes`
  0% {
    transform: scale(1, 1.3) translate3d(0, 0, 0);
    -webkit-filter: brightness(1);
    filter: brightness(1);
    opacity: 1;
  }
  60% {
    transform: scale(1.3, 0.001) translate3d(0, 0, 0);
    -webkit-filter: brightness(10);
    filter: brightness(10);
  }
  100% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: scale(0, 0.0001) translate3d(0, 0, 0);
    -webkit-filter: brightness(50);
    filter: brightness(50);
  }
`;

export { flicker, turnOn, turnOff };
