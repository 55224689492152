import { css } from "styled-components";

const Size = {
  // Desktop: 1200,
  // DesktopSmall: 1024,
  // Tablet: 768,
  Mobile: 1224,
  // Mobile: 480,
};

// /* React Responsive */
// const Desktop = props => <Responsive {...props} minWidth={Size.Desktop} />;

// const DesktopSmall = props => (
//   <Responsive
//     {...props}
//     minWidth={Size.DesktopSmall}
//     maxWidth={Size.Desktop - 1}
//   />
// );

// const Tablet = props => (
//   <Responsive
//     {...props}
//     minWidth={Size.Tablet}
//     maxWidth={Size.DesktopSmall - 1}
//   />
// );

// const Mobile = props => <Responsive {...props} maxWidth={Size.Tablet - 1} />;

// const TabletAndMobile = props => (
//   <Responsive {...props} maxWidth={Size.DesktopSmall - 1} />
// );
// /* */

/* Styled Components */
const Media = Object.keys(Size).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${Size[label] - 1}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});
/* */

export default Media;