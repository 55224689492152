import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import FooterMenu from "./FooterMenu";
import Balloon from "../../components/Balloon";

import { ARTIST } from "../constants/profiles";
import Colors from "../themes/colors";
import { AppIcons, TrayIcons } from "../themes/icons";

const getTime = () => {
  const date = new Date();
  let hour = date.getHours();
  let hourPostFix = "AM";
  let min = date.getMinutes();
  if (hour >= 12) {
    hour -= 12;
    hourPostFix = "PM";
  }
  if (hour === 0) {
    hour = 12;
  }
  if (min < 10) {
    min = "0" + min;
  }
  return `${hour}:${min} ${hourPostFix}`;
};

function Footer({
  onMouseDownApp,
  apps,
  focusedAppId,
  onMouseDown,
  onClickMenuItem,
  activeUser
}) {
  const [time, setTime] = useState(getTime);
  const [menuOn, setMenuOn] = useState(false);
  const menu = useRef(null);
  function toggleMenu() {
    setMenuOn(on => !on);
  }
  function _onMouseDown(e) {
    if (e.target.closest(".footer__window")) return;
    onMouseDown();
  }
  function _onClickMenuItem(name) {
    onClickMenuItem(name);
    setMenuOn(false);
  }
  useEffect(() => {
    const timer = setInterval(() => {
      const newTime = getTime();
      newTime !== time && setTime(newTime);
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  useEffect(() => {
    const target = menu.current;
    if (!target) return;
    function onMouseDown(e) {
      if (!target.contains(e.target) && menuOn) setMenuOn(false);
    }
    window.addEventListener("mousedown", onMouseDown);
    return () => window.removeEventListener("mousedown", onMouseDown);
  }, [menuOn]);

  return (
    <Container activeUser={activeUser} onMouseDown={_onMouseDown}>
      <div className="footer__items left">
        <div ref={menu} className="footer__start__menu">
          {menuOn && (
            <FooterMenu onClick={_onClickMenuItem} activeUser={activeUser} />
          )}
        </div>
        <img
          src={AppIcons.MENU}
          alt="start"
          className="footer__start"
          onMouseDown={toggleMenu}
        />
        {[...apps].map(
          app =>
            !app.header.noFooterWindow && (
              <FooterWindow
                key={app.id}
                id={app.id}
                icon={app.header.icon}
                title={app.header.title}
                onMouseDown={onMouseDownApp}
                isFocus={focusedAppId === app.id}
              />
            )
        )}
      </div>

      <div className="footer__items right">
        <img className="footer__icon" src={TrayIcons.VOLUME} alt="" />
        <img className="footer__icon" src={TrayIcons.SCREENSHARE} alt="" />
        <div style={{ position: "relative", width: 0, height: 0 }}>
          <Balloon activeUser={activeUser} />
        </div>
        <div className="footer__time">{time}</div>
      </div>
    </Container>
  );
}

function FooterWindow({ id, icon, title, onMouseDown, isFocus }) {
  function _onMouseDown() {
    onMouseDown(id);
  }
  return (
    <div
      onMouseDown={_onMouseDown}
      className={`footer__window ${isFocus ? "focus" : "cover"}`}
    >
      <img className="footer__icon" src={icon} alt={title} />
    </div>
  );
}

const Container = styled.footer`
  height: 44px;
  background: ${props => Colors[props.activeUser].Black};
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  .footer__items.left {
    height: 100%;
    flex: 1;
    overflow: hidden;
  }
  .footer__items.right {
    background-color: #0b77e9;
    flex-shrink: 0;
    background: ${props => Colors[props.activeUser].Dark};
    /* border-left: 1px solid #1042af; */
    /* box-shadow: inset 1px 0 1px #18bbff; */
    padding: 0 10px;
    margin-left: 10px;
  }
  .footer__items {
    display: flex;
    align-items: center;
  }
  .footer__start {
    height: 100%;
    max-width: 60px;
    position: relative;
    padding: 10px;
    background: ${props => Colors[props.activeUser].Black};
    &:hover {
      filter: brightness(105%);
    }
  }
  .footer__start__menu {
    position: absolute;
    left: 0;
    bottom: 100%;
  }
  .footer__window {
    flex: 1;
    max-width: 60px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-radius: 2px;
    margin-top: 2px; */
    padding: 0 8px
    border-bottom: 2px solid transparent;
    height: 100%;
    /* margin: 0 2px; */
    font-size: 11px;
    /* background-color: #3c81f3; */
    /* box-shadow: inset -1px 0px rgba(0, 0, 0, 0.3),
      inset 1px 1px 1px rgba(255, 255, 255, 0.2); */
    position: relative;
    display: flex;
    align-items: center;

    &:hover {
      border-bottom: 2px solid ${props => Colors[props.activeUser].Highlight};
    }
    transition: border-bottom-color 0.44s, background-color 0.44s;
  }
  .footer__icon {
    height: 35px;
    width: 35px;
    /* border-bottom: 2px solid black; */
    padding: 5px;
  }
  .footer__text {
    position: absolute;
    left: 27px;
    right: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .footer__window.cover:hover:active {
    background-color: ${props =>
      Colors[props.activeUser].Dark}; /* Click hold on active */
  }
  .footer__window.focus:hover { /* Hover on active */
    background-color: ${props => Colors[props.activeUser].Highlight};
  }
  .footer__window.focus:hover:active { /* Click hold on inactive */
    background-color: ${props => Colors[props.activeUser].Light};
  }
  .footer__window.focus {
    background-color: ${props => Colors[props.activeUser].Main};
    border-bottom: 2px solid #ddd;
  }
  .footer__time {
    margin: 0 5px;
    color: #fff;
    font-weight: lighter;
    text-shadow: none;
  }
`;

export default Footer;
