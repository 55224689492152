import React from "react";
import styled from "styled-components";

import { ARTIST } from "../constants/profiles";
import Colors from "../themes/colors";
import { WindowControlIcons } from "../themes/icons";

function HeaderButtons({
  buttons,
  onMaximize,
  onMinimize,
  onClose,
  maximized,
  resizable,
  className
}) {
  const buttonElements = {
    minimize: (
      <button
        key="minimize"
        className="header__button header__button--minimize"
        onMouseUp={onMinimize}
      />
    ),
    maximize: (
      <button
        key="maximize"
        className={`header__button ${
          maximized ? "header__button--maximized" : "header__button--maximize"
        } ${resizable ? "" : "header__button--disable"}`}
        onMouseUp={onMaximize}
      />
    ),
    close: (
      <button
        key="button"
        className="header__button header__button--close"
        onMouseUp={onClose}
      />
    )
  };

  return (
    <div className={className}>
      {buttons ? (
        buttons.map(b => buttonElements[b])
      ) : (
        <>
          {buttonElements.close}
          {buttonElements.minimize}
          {buttonElements.maximize}
        </>
      )}
    </div>
  );
}

export default styled(HeaderButtons)`
  opacity: ${({ isFocus }) => (isFocus ? 1 : 0.2)};
  height: 22px;
  display: flex;
  align-items: center;
  margin-top: -8px;
  .header__button {
      border: none;
  outline: none;
    position: relative;
    background-color: ${props => Colors[props.activeUser].Dark};
        background-position: center center;
    background-size: 14px;
    background-repeat: no-repeat;
    width: 40px;
    height: 20px;
    &:hover {
      background-color: ${props => Colors[props.activeUser].Black};
    }
    &:hover:active {
      background-color: ${props => Colors[props.activeUser].Black};
    }
  }
  .header__button--minimize {
    background-color: transparent;
    background-image: url(${WindowControlIcons.MIN});
    width: 28px;
  }
  .header__button--maximize {
    background-color: transparent;
        background-image: url(${WindowControlIcons.MAX});
    width: 28px;
  }
  .header__button--maximized {
        background-image: url(${WindowControlIcons.DEMAX});
  }
  .header__button--close {
        background-image: url(${WindowControlIcons.CLOSE});
    }
  }
  .header__button--disable {
    outline: none;
    opacity: 0.5;
    &:hover {
      filter: brightness(100%);
    }
  }
`;
