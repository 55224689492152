import React from "react";
import styled from "styled-components";

const Div = styled.div`
  background-color: #222;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Header = styled.h1`
  margin: 0;
  color: white;
`;

const Notes = () => (
  <Div>
    <Header>In Development</Header>
  </Div>
);

export default Notes;
