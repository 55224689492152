import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Download from "downloadjs";
import ga from "react-ga";

import { getImages } from "../../content";
import { ContentType } from "../../constants/contentful";
import { BrowserIcons } from "../../themes/icons";
import Colors from "../../themes/colors";
import { ARTIST } from "../../constants/profiles";

const Div = styled.div`
  background-color: #222;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const EmptyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Header = styled.h1`
  margin: 0;
  color: white;
`;

const TopBar = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ activeUser }) => Colors[ARTIST[activeUser]].Main};
`;

const BreadCrumbs = styled.p`
  margin: 0;
  color: white;
  margin-left: 20px;
`;

const BackButton = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 18px;
  background-color: ${({ activeUser }) => Colors[ARTIST[activeUser]].Dark};
  color: white;
  font-weight: 800;
  ${({ enough, activeUser }) =>
    enough &&
    css`
      cursor: pointer;
      &:hover {
        background-color: ${Colors[ARTIST[activeUser]].Main};
      }
    `}

  transition: background-color 0.2s;
`;

const IconContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: baseline;
  background-color: ${({ activeUser }) => Colors[ARTIST[activeUser]].Black};
  overflow-x: hidden;
  overflow-y: scroll;
`;

const IconHousing = styled.div`
  /* height: 120px;
  width: 120px; Think about text overflow on icon title? */
  width: ${props => 120 + props.slider}px;
  max-height: ${props => 130 + props.slider}px;
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  cursor: pointer;
  /* overflow: hidden; */
`;

const IconImage = styled.img`
  height: ${props => 90 + props.slider}px;
  max-width: ${props => 120 + props.slider}px;
  ${props =>
    props.isImage &&
    css`
      border: 2px solid white;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    `}
  ${props =>
    props.highlighted &&
    css`
      opacity: 0.8;
    `}
`;

const IconTitle = styled.p`
  margin: 0;
  margin-top: 8px;
  display: inline-block;
  text-align: center;
  width: ${props => 120 + props.slider}px;
  height: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  color: white;
  ${props =>
    props.highlighted &&
    css`
      background-color: #999;
    `}
`;

const imageThumbnail = url => `${url}?fit=pad&h=180&w=240`;

const Icon = ({
  name,
  url,
  isFolder,
  folderAction,
  imageAction,
  activeUser,
  slider,
  highlighted,
  wantsHighlight
}) => {
  // const [isHighlighted]
  const [doubleWaiting, setDoubleWaiting] = useState(false);

  const handleClick = () => {
    if (doubleWaiting) {
      if (isFolder) {
        folderAction();
      } else {
        imageAction();
      }
    } else {
      wantsHighlight();
      setDoubleWaiting(true);
      setTimeout(() => {
        setDoubleWaiting(false);
      }, 600);
    }
  };

  return (
    <IconHousing slider={slider} onClick={handleClick}>
      <IconImage
        slider={slider}
        isImage={!isFolder}
        src={isFolder ? BrowserIcons.FOLDER : imageThumbnail(url)}
        highlighted={highlighted}
      />
      <IconTitle slider={slider} highlighted={highlighted}>
        {name}
      </IconTitle>
    </IconHousing>
  );
};

const retrieveNestedChild = (inputArray, indexTree) =>
  indexTree.reduce((acc, current) => acc[current].contents, inputArray);

// const getBreadcrumbs = (inputArray, indexTree) =>
//   `/MyPictures/${indexTree.reduce(
//     (acc, current) => ({cont: acc[current].contents,
//     inputArray
//   )}`;

const getBreadcrumbs = (inputArray, indexTree) => {
  let output = "/MyPictures/";
  indexTree.reduce((acc, current) => {
    output += `${acc[current].name}/`;
    return acc[current].contents;
  }, inputArray);
  return output;
};

const MyPictures = ({ content, activeUser, isLoading }) => {
  const [baseContent, setBaseContent] = useState(null);
  const [activeContents, setActiveContents] = useState(null);
  const [contentTree, setContentTree] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState("/My Pictures/");
  const [highlightedIndex, setHighlightedIndex] = useState(null);

  /* BONUS ROUND */
  const [slider, setSlider] = useState(Number(0)); // 0 <====> 100

  useEffect(() => {
    const images = getImages({ content, asUser: activeUser });
    setBaseContent(images);
    setActiveContents(images);
  }, []);

  useEffect(() => {
    setHighlightedIndex(null);
    if (baseContent) {
      setActiveContents(retrieveNestedChild(baseContent, contentTree));
      setBreadcrumbs(getBreadcrumbs(baseContent, contentTree));
    }
  }, [contentTree]);

  return (
    <Div>
      <TopBar activeUser={activeUser}>
        <BackButton
          onClick={() => setContentTree(contentTree.slice(0, -1))}
          activeUser={activeUser}
          enough={contentTree.length}
        >
          {contentTree.length ? <span>&lt;&nbsp;Back</span> : <span />}
        </BackButton>
        <BreadCrumbs>{breadcrumbs}</BreadCrumbs>
      </TopBar>
      <IconContainer activeUser={activeUser}>
        {activeContents ? (
          activeContents.map(({ type, name, mime, ext, url }, index) => (
            <Icon
              isFolder={type === ContentType.imageFolder}
              name={name}
              url={url}
              folderAction={() => {
                setContentTree([...contentTree, index]);
                setHighlightedIndex(null);
                ga.event({
                  category: `My Pictures | ${activeUser}`,
                  action: "Opened Folder",
                  label: name,
                  value: 4
                });
              }}
              imageAction={() => {
                Download(`https:${url}`);
                setHighlightedIndex(null);
                ga.event({
                  category: `My Pictures | ${activeUser}`,
                  action: "Downloaded Image",
                  label: name,
                  value: 5
                });
              }}
              activeUser={activeUser}
              slider={slider}
              highlighted={index === highlightedIndex}
              wantsHighlight={() => setHighlightedIndex(index)}
            />
          ))
        ) : (
          <EmptyContainer>
            <Header>This Folder is Empty</Header>
          </EmptyContainer>
        )}
      </IconContainer>
      {/* <input
        type="range"
        value={slider}
        onChange={e => setSlider(Number(e.target.value))}
        min={0}
        max={40}
        style={{
          margin: "0.2rem 1rem 0.2rem auto",
          width: 100
        }}
      /> */}
    </Div>
  );
};

export default MyPictures;
