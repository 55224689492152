import React, { useRef, memo } from "react";
import useWindowSize from "react-use/lib/useWindowSize";
import { useElementResize } from "../../hooks";
import styled from "styled-components";

import HeaderButtons from "./HeaderButtons";

import { ARTIST } from "../constants/profiles";
import Colors from "../themes/colors";

function Windows({
  apps,
  onMouseDown,
  onClose,
  onMinimize,
  onMaximize,
  focusedAppId,
  activeUser
}) {
  return (
    <div style={{ position: "relative", zIndex: 0 }}>
      {apps.map(app => (
        <StyledWindow
          activeUser={activeUser}
          show={!app.minimized}
          key={app.id}
          id={app.id}
          onMouseDown={onMouseDown}
          onMouseUpClose={onClose}
          onMouseUpMinimize={onMinimize}
          onMouseUpMaximize={onMaximize}
          isFocus={focusedAppId === app.id} // for styledWindow
          {...app}
        />
      ))}
    </div>
  );
}

const Window = memo(function({
  injectProps,
  id,
  onMouseDown,
  onMouseUpClose,
  onMouseUpMinimize,
  onMouseUpMaximize,
  header,
  defaultSize,
  defaultOffset,
  resizable,
  maximized,
  component,
  zIndex,
  isFocus,
  className,
  activeUser
}) {
  function _onMouseDown() {
    onMouseDown(id);
  }
  function _onMouseUpClose() {
    onMouseUpClose(id);
  }
  function _onMouseUpMinimize() {
    onMouseUpMinimize(id);
  }
  function _onMouseUpMaximize() {
    if (resizable) onMouseUpMaximize(id);
  }
  const dragRef = useRef(null);
  const ref = useRef(null);
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const { offset, size } = useElementResize(ref, {
    dragRef,
    defaultOffset,
    defaultSize,
    boundary: {
      top: 1,
      right: windowWidth - 1,
      bottom: windowHeight - 31,
      left: 1
    },
    resizable,
    resizeThreshold: 10
  });
  let width, height, x, y;
  if (maximized) {
    width = windowWidth + 6;
    height = windowHeight - 24;
    x = -3;
    y = -3;
  } else {
    width = size.width;
    height = size.height;
    x = offset.x;
    y = offset.y;
  }
  return (
    <div
      className={className}
      ref={ref}
      onMouseDown={_onMouseDown}
      style={{
        transform: `translate(${x}px,${y}px)`,
        width: width ? `${width}px` : "auto",
        height: height ? `${height}px` : "auto",
        zIndex
      }}
    >
      <div className="header__bg" />
      <header className="app__header" ref={dragRef}>
        <HeaderButtons
          activeUser={activeUser}
          buttons={header.buttons}
          onMaximize={_onMouseUpMaximize}
          onMinimize={_onMouseUpMinimize}
          onClose={_onMouseUpClose}
          maximized={maximized}
          resizable={resizable}
          isFocus={isFocus}
        />

        <div className="app__header__title">{header.title}</div>
      </header>
      <div className="app__content">
        {component({
          onClose: _onMouseUpClose,
          onMinimize: _onMouseUpMinimize,
          isFocus,
          ...injectProps
        })}
      </div>
    </div>
  );
});

const StyledWindow = styled(Window)`
  display: ${({ show }) => (show ? "flex" : "none")};
  position: absolute;
  padding: ${({ header }) => (header.invisible ? 0 : 6)}px;
  background-color: ${({ isFocus, activeUser }) =>
    isFocus ? Colors[activeUser].Light : Colors[activeUser].Highlight};
  flex-direction: column;
  .header__bg {
    background-color: ${({ isFocus, activeUser }) =>
      isFocus ? Colors[activeUser].Light : Colors[activeUser].Highlight};
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 28px;
    pointer-events: none;
    overflow: hidden;
  }
  .header__bg:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    opacity: ${({ isFocus }) => (isFocus ? 1 : 0.3)};
    top: 0;
    bottom: 0;
    width: 15px;
  }
  .header__bg:after {
    content: "";
    opacity: ${({ isFocus }) => (isFocus ? 1 : 0.4)};
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 15px;
  }
  .app__header {
    display: ${({ header }) => (header.invisible ? "none" : "flex")};
    height: 28px;
    line-height: 25px;
    font-weight: 700;
    font-size: 12px;
    color: white;
    position: absolute;
    left: 6px;
    right: 6px;
    top: 0;
    align-items: center;
  }
  .app__header__icon {
    pointer-events: none;
    width: 15px;
    height: 15px;

    margin-right: 3px;
  }
  .app__header__title {
    pointer-events: none;
    padding-right: 70px;
    letter-spacing: 0.5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: auto;
    margin-right: auto;
  }
  .app__content {
    flex: 1;
    position: relative;
    margin-top: 25px;
    height: calc(100% - 25px);
  }
`;

export default Windows;
