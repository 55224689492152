import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, withRouter } from "react-router-dom";
import "react-typist/dist/Typist.css";
import styled from "styled-components";
import ga from "react-ga";
import CookieConsent from "react-cookie-consent";

import Preface from "./Preface";
import Mainframe from "./Mainframe";
import { useGA, useFBPixel } from "./hooks";

import { fetchAllData, processDataForUser } from "./OS/content";
import { ARTIST } from "./OS/constants/profiles";

const IS_PREVIEW = process.env.REACT_APP_P_MODE;

const PreviewLabel = styled.h1`
  position: fixed;
  top: 0;
  right: 0;
  background-color: white;
  color: red;
  opacity: 0.4;
  margin: 2rem;
  padding: 0.2rem;
  font-size: 18px;
  z-index: 1000000;
  pointer-events: none;
`;

const App = () => {
  const [baseContent, setBaseContent] = useState(null);
  const [content, setContent] = useState(null);
  const [activeUser, setActiveUser] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [contentError, setContentError] = useState(false);

  const loadData = async () => {
    try {
      const allData = await fetchAllData();
      if (IS_PREVIEW) {
        console.log(JSON.stringify(allData));
      }
      setBaseContent(allData);
    } catch (error) {
      if (IS_PREVIEW) {
        console.log(JSON.stringify(error));
      }
      setContentError(true);
    }
    setLoading(false);
  };
  useEffect(() => {
    loadData();
  }, []);

  useGA("UA-143617949-1", "PLAY");
  useFBPixel("119220402102775", "PLAY");

  const logOut = () => {
    setActiveUser(null);
  };

  return (
    <Router>
      {IS_PREVIEW && <PreviewLabel>PREVIEW MODE</PreviewLabel>}
      <CookieConsent
        style={{ zIndex: 99999999, fontSize: 18, color: "white" }}
        buttonStyle={{ fontSize: 22, margin: "auto 15px" }}
        buttonText="✓ Accept Cookies"
      >
        We're using software to track your activity on this website.
        <br />
        Some of this data may be used in targeting you with marketing.
        <br />
        <a style={{ color: "white" }} href="/privacy-policy.txt">
          Read more or administrate data sharing
        </a>
      </CookieConsent>
      <Route
        path="/"
        exact
        render={props => (
          <Mainframe
            {...props}
            isLoading={isLoading}
            contentError={contentError}
            activeUser={activeUser}
            setActiveUser={user => {
              try {
                const userContent = processDataForUser({
                  content: baseContent,
                  asUser: user
                });
                setContent(userContent);
                setActiveUser(user);
              } catch (error) {
                setContentError(true);
                if (IS_PREVIEW) {
                  throw error;
                  console.log(JSON.stringify(error.message));
                }
                ga.event({
                  category: "Error",
                  action: "Content Error Loading Artist",
                  label: user,
                  value: 10,
                  nonInteraction: true
                });
              }
              ga.event({
                category: "Artist",
                action: "Selected Artist",
                label: user,
                value: 8
              });
            }}
          />
        )}
      />
      <Route
        path="/client-connection"
        render={props => (
          <Preface
            {...props}
            activeUser={activeUser}
            content={content}
            isLoading={isLoading}
            logOut={logOut}
          />
        )}
      />
    </Router>
  );
};

export default App;
