import { ARTIST } from "../constants/profiles";

import appIconMenu from "../../assets/app-icons/menu.png";
import appIconChat from "../../assets/app-icons/chat.png";
import appIconImages from "../../assets/app-icons/images.png";
import appIconMixer from "../../assets/app-icons/mixer.png";
import appIconNotes from "../../assets/app-icons/notes.png";
import appIconVideo from "../../assets/app-icons/video.png";

import controlIconMaximise from "../../assets/misc-icons/maximise.png";
import controlIconDemaximise from "../../assets/misc-icons/demaximise.png";
import controlIconMinimise from "../../assets/misc-icons/minimise.png";
import controlIconClose from "../../assets/misc-icons/close.png";

import trayIconLogout from "../../assets/misc-icons/logout.png";
import trayIconVolume from "../../assets/misc-icons/volume.png";
import trayIconScreenShare from "../../assets/misc-icons/screenshare.png";

import browserIconFolder from "../../assets/misc-icons/folder.png";

import profilePictureAW from "../../assets/profile-icons/aw-profile_prod.jpg";
import profilePictureTV from "../../assets/profile-icons/tv-profile_prod.jpg";
import profilePictureK3 from "../../assets/profile-icons/k3-profile_prod.jpg";

import wallpaperAW from "../../assets/wallpapers/all-wallpaper.jpg";
import wallpaperTV from "../../assets/wallpapers/all-wallpaper.jpg";
import wallpaperK3 from "../../assets/wallpapers/all-wallpaper.jpg";

const Profiles = {
  [ARTIST.AW]: profilePictureAW,
  [ARTIST.TV]: profilePictureTV,
  [ARTIST.K3]: profilePictureK3
};

const Wallpapers = {
  [ARTIST.AW]: wallpaperAW,
  [ARTIST.TV]: wallpaperTV,
  [ARTIST.K3]: wallpaperK3
};

const AppIcons = {
  MENU: appIconMenu,
  CHAT: appIconChat,
  IMAGES: appIconImages,
  MIXER: appIconMixer,
  NOTES: appIconNotes,
  VIDEO: appIconVideo
};

const WindowControlIcons = {
  MAX: controlIconMaximise,
  DEMAX: controlIconDemaximise,
  MIN: controlIconMinimise,
  CLOSE: controlIconClose
};

const TrayIcons = {
  LOGOUT: trayIconLogout,
  VOLUME: trayIconVolume,
  SCREENSHARE: trayIconScreenShare
};

const BrowserIcons = {
  FOLDER: browserIconFolder
};

export {
  Profiles,
  Wallpapers,
  AppIcons,
  WindowControlIcons,
  TrayIcons,
  BrowserIcons
};
