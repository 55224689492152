const ContentType = {
  chatUser: "chatUser",
  chatRoom: "chatRoom",
  textMessage: "textMessage",
  imageMessage: "imageMessage",
  videoMessage: "videoMessage",
  image: "image",
  imageFolder: "imageFolder",
  flStudioLibrary: "flStudioLibrary",
  flStudioVideo: "flStudioVideo"
};

const UserID = {
  AW: "4DhEjLmirhfMQNDKehY0x8",
  K3: "2Bm721SlbRnGx9nb5Pdb6T",
  TV: "5XqEZJV38DjxKTZJHdI6el"
};

const RootImageFolder = {
  AW: "2FJNX43E0mfcykI7Rbr5cM",
  K3: "5NxY21YG9sXwIBMiGpArev",
  TV: "5SC5CHe4qXilNyuCfVzxls"
};

const RootFLFolder = {
  AW: "6RijBfTQM5W5y2S7XVce3Z",
  K3: "4DNrwCl3t8qr9l7reKBPmh",
  TV: "66cWxXqBjSoR5Ez3fBET4I"
};

export { ContentType, UserID, RootImageFolder, RootFLFolder };
