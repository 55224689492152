const ARTIST = {
  AW: "AW",
  TV: "TV",
  K3: "K3"
};

const getArtistName = artist => {
  switch (artist) {
    default:
    case ARTIST.AW:
      return "Alan Walker";
    case ARTIST.TV:
      return "Tungevaag";
    case ARTIST.K3:
      return "K-391";
  }
};

const getArtistPassword = artist => {
  switch (artist) {
    case ARTIST.AW:
      return "faded";
    case ARTIST.TV:
      return "badboy";
    case ARTIST.K3:
      return "origin";
    default:
      return "no_pass";
  }
};

const artistPINs = {
  AW: "32333",
  TV: "223269",
  K3: "674446"
};

const getArtistPIN = artist => {
  switch (artist) {
    default:
      return "oops";
  }
};

const verifyArtistPassword = ({ artist, password }) =>
  password.toLowerCase() === getArtistPassword(artist);

const getArtistForPin = input =>
  Object.entries(artistPINs)
    .filter(([artist, pin]) => input === pin)
    .map(([artist, pin]) => artist);

export { ARTIST, getArtistName, verifyArtistPassword, getArtistForPin };
