import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { useMediaQuery } from "react-responsive";
import KeyboardEventHandler from "react-keyboard-event-handler";
import Typist from "react-typist";

import "./text-glitch.css";
import pressPlayLogo from "../assets/play-assets/play-logo-vhs.png";
import pressPlayLogoHover from "../assets/play-assets/play-logo-vhs-exposed.png";
import youTubeLogo from "../assets/play-assets/play-icon-clean.png";
// import "./screen-glitch.css";

import { flicker, turnOn, turnOff } from "./keyframes";

import {
  ARTIST,
  getArtistName,
  verifyArtistPassword,
  getArtistForPin
} from "../OS/constants/profiles";
import { Profiles } from "../OS/themes/icons";

const Background = styled.div`
  height: 100vh;
  width: 100vw;
  background: #222;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 1s ease-in;
  flex-direction: column;
`;

const TerminalText = styled.span`
  color: ${props => props.color || "white"};
  font-family: "Roboto Mono", monospace;
  font-size: ${props => (props.big ? 20 : 16)}px;
  display: inline;
  text-shadow: 0px 0px 20px #d588ff;
  user-select: none;
`;

const DesktopLink = styled.div`
  width: 20px;
  height: 20px;
  position: fixed;
  bottom: 40px;
  right: 40px;
  background-color: #222;

  &:hover {
    background-color: #d588ff;
  }
`;

const ProfileIcons = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

const ProfileIcon = styled.div`
  width: 6rem;
  height: 6rem;
  border-radius: 10rem;
  border: 2px solid white;
  background-color: #000;
  background-image: url(${props => props.photoURL});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  margin: ${props => (props.mobileMargin ? "1" : "2")}rem;
`;

const TVContainer = styled.div`
  max-width: 800px;
  max-height: 500px;
  height: 60vh;
  width: 80vw;
  background-color: ${props => (props.live ? "#222" : "#1f1f1f")};
  border: 3px solid transparent;
  transition: background-color 1s ease-in, box-shadow 0.9s ease,
    border-color 1s ease;
  ${props =>
    props.live &&
    css`
      /* box-shadow: 0px 0px 40px 0px rgba(179, 255, 172, 0.8); */
      box-shadow: inset 0px 0px 10px 0px #d588ff;
      border-color: #333;
    `}

  margin-top: 10px;
  position: relative;
  overflow: hidden;

  &::after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(18, 16, 16, 0.1);
    opacity: 0;
    z-index: 2;
    pointer-events: none;
    ${props =>
      props.live &&
      css`
        animation: ${flicker} 0.15s infinite;
      `}
  }

  &::before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(
        rgba(18, 16, 16, 0) 50%,
        rgba(0, 0, 0, 0.25) 50%
      ),
      linear-gradient(
        90deg,
        rgba(255, 0, 0, 0.06),
        rgba(0, 255, 0, 0.02),
        rgba(0, 0, 255, 0.06)
      );
    z-index: 2;
    background-size: 100% 2px, 3px 100%;
    pointer-events: none;
  }
`;

const PinContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

const TVScreen = styled.div`
  width: 100%;
  height: 100%;
  border: none;
  background-color: #222;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${props =>
    props.live
      ? css`
          animation: ${turnOn} 4s linear;
          animation-fill-mode: forwards;
          box-shadow: inset 0px 0px 10px 0px #d588ff;
        `
      : css`
          animation: ${turnOff} 0s cubic-bezier(0.23, 1, 0.32, 1);
          animation-fill-mode: forwards;
        `};

  &::-webkit-scrollbar {
    display: none;
  }
`;

const TVTitle = styled.h1`
  margin: 0;
  font-family: "VT323", monospace;
  background-color: #222;
  padding: 10px;
`;

const PlayContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  display: ${props => (props.live ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  background-color: black;
  top: 0;
  left: 0;
`;

const playTextKeyframes = keyframes`
  0% {
    opacity: 0.34;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.34;
  }
`;

const PlayLink = styled.div`
  align-items: center;
  justify-content: center;
  transition: color 0.4s, text-shadow 0.4s;
  /* animation: ${playTextKeyframes} 3s ease-in 0s infinite; */
  &:hover {
    cursor: pointer;
    color: #D588FF;
    text-shadow: 0px 0px 46px #D588FF;
    animation: none;
  }
  display: ${props => (props.live ? "flex" : "none")};
  user-select: none;
  /* position: relative; */
`;

const PlayPrompt = styled.h1`
  font-size: ${props => (props.arrow ? 2.6 : 4)}rem !important;
  margin-left: ${props => (props.arrow ? 1.6 : 0)}rem !important;
  font-family: "VT323", monospace;
  /* color: #eee; */
`;

const PlayImage = styled.img`
  max-width: 600px;
  /* position: ${props => (props.overlay ? "absolute" : "relative")}; */
`;

const YouTubeIcon = styled.img`
  height: 6rem;
`;

const YouTubeLink = styled.a`
  height: 6rem;
`;
/*
 _______  _____          _     ____  ____  
|_   __ \|_   _|        / \   |_  _||_  _| 
  | |__) | | |         / _ \    \ \  / /   
  |  ___/  | |   _    / ___ \    \ \/ /    
 _| |_    _| |__/ | _/ /   \ \_  _|  |_    
|_____|  |________||____| |____||______|   
                                           
*/

const CodeBackground = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  overflow-y: scroll;
  opacity: 0.6;
  user-select: none;
  pointer-events: none;
`;

const PrivacyPolicy = styled.a`
  background-color: #111;
  color: #aaa;
  margin-top: 14px;
  padding: 10px;
  position: ${props => (props.item ? "relative" : "fixed")};
  bottom: 10px;
  z-index: 99999;
  ${props =>
    props.mobileHide
      ? css`
          display: none;
        `
      : css`
          right: ${props.right ? 0 : "initial"};
          left: ${props.right ? "initial" : 0};
        `}
`;

const updateLineFeed = () => {
  const screen = document.getElementById("screen-scroller");
  if (screen) {
    const isAtBottom =
      screen.scrollHeight - screen.clientHeight <= screen.scrollTop + 1;

    if (!isAtBottom) {
      screen.scrollTop = screen.scrollHeight - screen.clientHeight;
    }
  }
};

const Mainframe = ({
  activeUser,
  setActiveUser,
  history,
  isLoading,
  contentError
}) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)"
  });

  const [playPressed, setPlayPressed] = useState(false);
  const [readyForArtist, setReadyForArtist] = useState(false);
  const [readyForPassword, setReadyForPassword] = useState(false);
  const [chosenArtist, setChosenArtist] = useState(null);
  const [password, setPassword] = useState("");
  const [passwordIncorrect, setPasswordIncorrect] = useState(false);
  const [pinCode, setPinCode] = useState("");
  const [playHover, setPlayHover] = useState(false);

  useEffect(() => {
    if (activeUser) {
      history.push("./client-connection");
    }
  }, [activeUser]);

  useEffect(() => {
    if (pinCode.length > 10) {
      setPinCode("");
      return;
    }
    const artist = getArtistForPin(pinCode);
    if (artist && artist.length) {
      setActiveUser(artist[0]);
    }
  }, [pinCode]);

  let title = "Loading...";

  if (!isLoading) {
    title = "Choose User to Access:";
  }

  if (contentError) {
    title = "[Screen Sharing Currently Unavailable] // Try again later...";
  }

  return (
    <Background live={playPressed}>
      <CodeBackground id="screen-scroller">
        {playPressed && (
          <Typist
            avgTypingDelay={6}
            onLineTyped={updateLineFeed}
            onTypingDone={() => setReadyForArtist(true)}
            cursor={{
              show: true,
              blink: true,
              element: "|",
              hideWhenDone: true,
              hideWhenDoneDelay: 0
            }}
          >
            <Typist.Delay ms={600} />
            <TerminalText color="#D588FF">=============</TerminalText>
            <br />
            <TerminalText>
              Interface_Connection.exe version 14.238b
            </TerminalText>
            <br />
            <TerminalText color="#D588FF">=============</TerminalText>
            <br />
            <TerminalText>COMPUTER SYSTEM</TerminalText>
            <br />
            <TerminalText>Hackers... beware 😵</TerminalText>
            <br />
            <TerminalText color="#D588FF">=============</TerminalText>
            <br />
            <Typist.Delay ms={1200} />
            <TerminalText color="#999">...</TerminalText>
            <br />
            <TerminalText color="#999">...</TerminalText>
            <br />
            <TerminalText color="#999">...</TerminalText>
            <br />
            <TerminalText color="#999">...</TerminalText>
            <br />
            <TerminalText color="#999">...</TerminalText>
            <br />
            <TerminalText color="#999">...</TerminalText>
            <br />
            <TerminalText color="#999">...</TerminalText>
            <br />
            <TerminalText color="#999">...</TerminalText>
            <br />
            <TerminalText color="#999">...</TerminalText>
            <br />
            <TerminalText color="#999">...</TerminalText>
            <br />
            <TerminalText color="#999">...</TerminalText>
            <br />
            <TerminalText color="#999">...</TerminalText>
            <br />
            <TerminalText color="#999">...</TerminalText>
            <br />
            <TerminalText color="#999">...</TerminalText>
            <br />
            <TerminalText>CONNECTING TO NETWORK...</TerminalText>
            <br />
            <Typist.Delay ms={440} />
            <TerminalText>CONNECTION ESTABLISHED ✅</TerminalText>
            <br />
            <Typist.Delay ms={440} />
            <TerminalText>3x3cut1ng m41nfr4m3 c0d3</TerminalText>
            <br />
            <TerminalText color="#999">...</TerminalText>
            <br />
            <TerminalText color="#999">...</TerminalText>
            <br />
            <TerminalText color="#999">...</TerminalText>
            <br />
            <TerminalText>
              ☠️️️️️️️☠️️️️️️️☠️️️️️️️☠️️️️️️️☠️️️️️️️☠️️️️️️️☠️️️️️️️☠️️️️️️️
            </TerminalText>
            <br />
            <Typist.Delay ms={880} />
            <br />
            <TerminalText color="pink">MAINFRAME ACCESSED</TerminalText>
            <br />
            <TerminalText color="#999">...</TerminalText>
            <br />
            <TerminalText>Received keep-alive...</TerminalText>
            <br />
            <TerminalText color="#999">...</TerminalText>
            <br />
            <TerminalText color="#999">...</TerminalText>
            <br />
            <TerminalText color="#999">...</TerminalText>
            <br />
            <TerminalText color="#999">...</TerminalText>
            <br />
            <TerminalText color="#999">...</TerminalText>
            <br />
            <TerminalText color="#999">...</TerminalText>
            <br />
            <TerminalText color="#999">...</TerminalText>
            <br />
            <TerminalText>Spoofing Handshake...</TerminalText>
            <br />
            <TerminalText color="#999">...</TerminalText>
            <br />
            <TerminalText color="#999">...</TerminalText>
            <br />
            <TerminalText>
              Loading
              <br />
              [===================================================================================
              <br />
              ============================================================================================================================]
            </TerminalText>
            <br />
            <TerminalText>
              Connection will be maintained for 40 minutes.
            </TerminalText>
            <br />
            <TerminalText color="#999">...</TerminalText>
            <br />
            <TerminalText color="#999">...</TerminalText>
          </Typist>
        )}
        {chosenArtist && (
          <Typist
            avgTypingDelay={10}
            onLineTyped={updateLineFeed}
            onTypingDone={() => setReadyForPassword(true)}
            cursor={{
              show: true,
              blink: true,
              element: "|",
              hideWhenDone: true,
              hideWhenDoneDelay: 0
            }}
          >
            <Typist.Delay ms={600} />
            <TerminalText color="#D588FF">=============</TerminalText>
            <br />
            <TerminalText color="yellow">
              Type password for {getArtistName(chosenArtist)} and press Enter:
            </TerminalText>
            <br />
          </Typist>
        )}
        {passwordIncorrect && password === "" && (
          <TerminalText color="pink">
            PASSWORD INCORRECT. TRY AGAIN
          </TerminalText>
        )}
        {readyForPassword && chosenArtist && (
          <TerminalText>{password}</TerminalText>
        )}
      </CodeBackground>

      {isDesktopOrLaptop ? (
        <>
          <TVContainer live={playPressed}>
            <TVScreen live={playPressed}>
              <TVTitle>{title}</TVTitle>
              {!isLoading && !contentError && (
                <>
                  <ProfileIcons>
                    {Object.values(ARTIST).map(artistTag => (
                      <ProfileIcon
                        onClick={() => setActiveUser(artistTag)}
                        photoURL={Profiles[artistTag]}
                      />
                    ))}
                  </ProfileIcons>
                  <YouTubeLink href="https://orcd.co/p74y" target="_blank">
                    <YouTubeIcon src={youTubeLogo} />
                  </YouTubeLink>
                </>
              )}
            </TVScreen>
          </TVContainer>
          {/* <KeyboardEventHandler
            handleKeys={["shift+a"]}
            onKeyEvent={() => setActiveUser(ARTIST.AW)}
          />
          <KeyboardEventHandler
            handleKeys={["shift+t"]}
            onKeyEvent={() => setActiveUser(ARTIST.TV)}
          />
          <KeyboardEventHandler
            handleKeys={["shift+k"]}
            onKeyEvent={() => setActiveUser(ARTIST.K3)}
          />
          <KeyboardEventHandler
            handleKeys={["alphanumeric", "backspace", "enter"]}
            onKeyEvent={(key, e) => {
              if (readyForArtist && !chosenArtist) {
                switch (key) {
                  case "a":
                    setChosenArtist(ARTIST.AW);
                    break;
                  case "t":
                    setChosenArtist(ARTIST.TV);
                    break;
                  case "k":
                    setChosenArtist(ARTIST.K3);
                    break;
                  default:
                    //
                    break;
                }
              }

              if (readyForPassword && chosenArtist) {
                updateLineFeed();
                let currentPassword = `${password}`;
                switch (key) {
                  case "backspace":
                    currentPassword = currentPassword.slice(0, -1);
                    break;
                  case "enter":
                    const passwordCorrect = verifyArtistPassword({
                      artist: chosenArtist,
                      password: currentPassword
                    });
                    if (passwordCorrect) {
                      setActiveUser(chosenArtist);
                    } else {
                      setPassword("");
                      setPasswordIncorrect(true);
                    }
                    return;
                  default:
                    currentPassword += key;
                    break;
                }
                setPassword(currentPassword);
              }
            }}
          /> */}
        </>
      ) : (
        playPressed && (
          <PinContainer>
            <TVTitle>{title}</TVTitle>
            {!isLoading && !contentError && (
              <>
                {Object.values(ARTIST).map(artistTag => (
                  <ProfileIcon
                    onClick={() => setActiveUser(artistTag)}
                    photoURL={Profiles[artistTag]}
                    mobileMargin
                  />
                ))}
                <YouTubeLink href="https://orcd.co/p74y" target="_blank">
                  <YouTubeIcon src={youTubeLogo} />
                </YouTubeLink>
                <PrivacyPolicy item href="/privacy-policy.txt">
                  Privacy Policy
                </PrivacyPolicy>
                <PrivacyPolicy item href="/terms-and-conditions.txt">
                  Terms &amp; Conditions
                </PrivacyPolicy>
              </>
            )}
          </PinContainer>
        )
      )}

      <PlayContainer live={!playPressed}>
        <PlayLink live={!playPressed} onClick={() => setPlayPressed(true)}>
          {/* <PlayPrompt className="glitch" data-text="PRESS PLAY">
              PRESS PLAY
            </PlayPrompt> */}
          {isDesktopOrLaptop ? (
            <PlayImage
              width="50%"
              onMouseOver={() => setPlayHover(true)}
              onMouseOut={() => setPlayHover(false)}
              className="glitch"
              src={playHover ? pressPlayLogoHover : pressPlayLogo}
            />
          ) : (
            <PlayImage
              width="50%"
              className="glitch"
              src={playHover ? pressPlayLogoHover : pressPlayLogo}
            />
          )}

          {/* <PlayImage
            overlay
            width="50%"
            // onMouseOver={() => setPlayHover(true)}
            // onMouseOut={() => setPlayHover(false)}
            className="glitch"
            // src={playHover ? pressPlayLogoHover : pressPlayLogo}
          /> */}
        </PlayLink>
      </PlayContainer>
      <PrivacyPolicy
        mobileHide={!isDesktopOrLaptop && playPressed}
        href="/privacy-policy.txt"
      >
        Privacy Policy
      </PrivacyPolicy>
      <PrivacyPolicy
        mobileHide={!isDesktopOrLaptop && playPressed}
        href="/terms-and-conditions.txt"
        right
      >
        Terms &amp; Conditions
      </PrivacyPolicy>
    </Background>
  );
};

export default Mainframe;
