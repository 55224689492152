import Chat from "./PLAY/Chat";
import FLStudio from "./PLAY/FLStudio";
import MyPictures from "./PLAY/MyPictures";
import VideoLibrary from "./PLAY/VideoLibrary";
import Notes from "./PLAY/Notes";
import ErrorBox from "./ErrorBox";

import error from "../../assets/misc-icons/close.png";

import { AppIcons } from "../themes/icons";

const gen = () => {
  let id = -1;
  return () => {
    id += 1;
    return id;
  };
};
const genId = gen();
const genIndex = gen();
export const defaultAppState = [
  // {
  //   component: Chat,
  //   header: {
  //     title: "Messages",
  //     icon: AppIcons.CHAT
  //   },
  //   defaultSize: {
  //     width: 980,
  //     height: 500
  //   },
  //   defaultOffset: {
  //     x: 130,
  //     y: 20
  //   },
  //   resizable: true,
  //   minimized: false,
  //   maximized: window.innerWidth < 800,
  //   id: genId(),
  //   zIndex: genIndex()
  // }
  // {
  //   component: InternetExplorer,
  //   header: {
  //     title: "Messages",
  //     icon: AppIcons.CHAT
  //   },
  //   defaultSize: {
  //     width: 700,
  //     height: 500
  //   },
  //   defaultOffset: {
  //     x: 130,
  //     y: 20
  //   },
  //   resizable: true,
  //   minimized: false,
  //   maximized: window.innerWidth < 800,
  //   id: genId(),
  //   zIndex: genIndex()
  // },
  // {
  //   component: InternetExplorer,
  //   header: {
  //     title: "Messages",
  //     icon: AppIcons.CHAT
  //   },
  //   defaultSize: {
  //     width: 700,
  //     height: 500
  //   },
  //   defaultOffset: {
  //     x: 130,
  //     y: 20
  //   },
  //   resizable: true,
  //   minimized: false,
  //   maximized: window.innerWidth < 800,
  //   id: genId(),
  //   zIndex: genIndex()
  // },
  // {
  //   component: InternetExplorer,
  //   header: {
  //     title: "Messages",
  //     icon: AppIcons.CHAT
  //   },
  //   defaultSize: {
  //     width: 700,
  //     height: 500
  //   },
  //   defaultOffset: {
  //     x: 130,
  //     y: 20
  //   },
  //   resizable: true,
  //   minimized: false,
  //   maximized: window.innerWidth < 800,
  //   id: genId(),
  //   zIndex: genIndex()
  // },
  // {
  //   component: InternetExplorer,
  //   header: {
  //     title: "Messages",
  //     icon: AppIcons.CHAT
  //   },
  //   defaultSize: {
  //     width: 700,
  //     height: 500
  //   },
  //   defaultOffset: {
  //     x: 130,
  //     y: 20
  //   },
  //   resizable: true,
  //   minimized: false,
  //   maximized: window.innerWidth < 800,
  //   id: genId(),
  //   zIndex: genIndex()
  // }
];

export const defaultIconState = [
  {
    id: 0,
    icon: AppIcons.CHAT,
    title: "Chat",
    component: Chat,
    isFocus: false
  },
  {
    id: 1,
    icon: AppIcons.VIDEO,
    title: "Video Library",
    component: FLStudio,
    isFocus: false
  },
  {
    id: 2,
    icon: AppIcons.IMAGES,
    title: "My Pictures",
    component: MyPictures,
    isFocus: false
  },
  // {
  //   id: 3,
  //   icon: AppIcons.VIDEO,
  //   title: "Video Library",
  //   component: VideoLibrary,
  //   isFocus: false
  // },
  {
    id: 4,
    icon: AppIcons.NOTES,
    title: "Notes",
    component: Notes,
    isFocus: false
  }
];

export const appSettings = {
  Chat: {
    header: {
      icon: AppIcons.CHAT,
      title: "Chat"
    },
    component: Chat,
    defaultSize: {
      width: 900,
      height: 500
    },
    defaultOffset: {
      x: 140,
      y: 30
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: false
  },
  "Video Library": {
    header: {
      icon: AppIcons.VIDEO,
      title: "Video Library"
    },
    component: FLStudio,
    defaultSize: {
      width: 900,
      height: 500
    },
    defaultOffset: {
      x: 150,
      y: 40
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: true
  },
  "My Pictures": {
    header: {
      icon: AppIcons.IMAGES,
      title: "My Pictures"
    },
    component: MyPictures,
    defaultSize: {
      width: 900,
      height: 500
    },
    defaultOffset: {
      x: 160,
      y: 50
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: true
  },
  // "Video Library": {
  //   header: {
  //     icon: AppIcons.VIDEO,
  //     title: "Video Library"
  //   },
  //   component: VideoLibrary,
  //   defaultSize: {
  //     width: 700,
  //     height: 500
  //   },
  //   defaultOffset: {
  //     x: 170,
  //     y: 60
  //   },
  //   resizable: true,
  //   minimized: false,
  //   maximized: window.innerWidth < 800,
  //   multiInstance: true
  // },
  Notes: {
    header: {
      icon: AppIcons.NOTES,
      title: "Notes"
    },
    component: Notes,
    defaultSize: {
      width: 700,
      height: 500
    },
    defaultOffset: {
      x: 180,
      y: 70
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: true
  },
  Error: {
    header: {
      icon: error,
      title: "SCREEN SHARING | ACCESS DENIED",
      buttons: ["close"],
      noFooterWindow: true
    },
    component: ErrorBox,
    defaultSize: {
      width: 380,
      height: 0
    },
    defaultOffset: {
      x: window.innerWidth / 2 - 190,
      y: window.innerHeight / 2 - 60
    },
    resizable: false,
    minimized: false,
    maximized: false,
    multiInstance: true
  }
};

export { error };
