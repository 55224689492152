import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
// import moment from "moment";
// RCE CSS
import "react-chat-elements/dist/main.css";
// MessageBox component
import { MessageList, ChatList, Popup } from "react-chat-elements";
import ga from "react-ga";

import { getAssetURL } from "../../../content";

import { ContentType, UserID } from "../../../constants/contentful";
import { ARTIST, getArtistName } from "../../../constants/profiles";
import "./chat-overrides.css";

import Colors from "../../../themes/colors";

import MediaQuery from "../../../../components/MediaQuery";

const Div = styled.div`
  background-color: ${props => Colors[props.activeUser].Dark};
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${MediaQuery.Mobile`
    width: 100vw;
    height: ${props => (props.mobileDetail ? "auto" : "100vh")};
    overflow-y: ${props => (props.mobileDetail ? "visible" : "hidden")};
    align-items: flex-start;
  `};
`;

const MainContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  ${MediaQuery.Mobile`
    width: 100vw;
    /* transform: translateX(-${props => (props.showDetail ? 100 : 0)}vw); */
    overflow-x: hidden;
    /*
    height: 100vh;
    overflow-y: hidden;
    */
  `} /* position: relative; */
  transition: transform 0.4s;
`;

const ChatListContainer = styled.div`
  height: 100%;
  flex: 1;
  min-width: 300px;
  overflow-y: scroll;
  background-color: #fff;
  border-right: 1px solid ${props => Colors[props.activeUser].Highlight};
  ${MediaQuery.Mobile`
    width: 100vw;
    flex: 1;
    border: none;
    padding-top: 50px;
  `}
`;

const MessageHousingContainer = styled.div`
  height: 100%;
  flex: 2.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${MediaQuery.Mobile`
    width: 100vw;
    flex: 1;
    padding-top: 50px;
  `}
`;

const MessageListContainer = styled.div`
  height: 100%;
  overflow-y: scroll;
  padding: 2rem;
  width: 100%;
  background-color: ${props => Colors[props.activeUser].Dark};
  ${MediaQuery.Mobile`
    padding: 1rem 0.6rem;
    overflow-y: visible;
    height: auto;
    min-height: calc(100vh - 50px);
  `};
`;

const Header = styled.h1`
  margin: 0;
  color: white;
`;

const TopBarContainer = styled.div`
  height: 50px;
  width: 100%;
  /* border-right: 1px solid ${props => Colors[props.activeUser].Light}; */
  background-color: ${props => Colors[props.activeUser].Main};
  display: flex;
  align-items: center;
  justify-content: center;
  ${MediaQuery.Mobile`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 2000;
  `}
`;

const TopBarContacts = styled.div`
  flex: 1;
  min-width: 300px;
  border-right: 1px solid ${props => Colors[props.activeUser].Highlight};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${MediaQuery.Mobile`
    border: none;
  `}
`;

const TopBarMessages = styled.div`
  flex: 2.5;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* padding: 0 2rem; */
`;

const TopBarDescriptor = styled.h3`
  margin: 0;
  margin-left: 20px;
  font-weight: 600;
  font-size: 14px;
  color: white;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const SendBar = styled.div`
  height: 50px;
  width: 100%;
  background-color: ${props => Colors[props.activeUser].Highlight};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  ${MediaQuery.Mobile`
    position: fixed;
    top: calc(100vh - 50px);
    left: 0;
    width: 100vw;
    z-index: 3000;
  `}
`;

const FakeInput = styled.input`
  background-color: white;
  height: 30px;
  border-radius: 6px;
  font-size: 12px;
  color: black;
  outline: none;
  border: none;
  user-select: none;
  pointer-events: none;
  cursor: not-allowed;
  padding-left: 12px;
  flex: 4;
`;

const SendMessage = styled.div`
  height: 30px;
  padding: 0 12px;
  background-color: #bbb;
  color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  border-radius: 6px;
  margin-left: 10px;
  flex: 1;
`;

const PhotoPop = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('${props => props.link}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #eee;
`;

const LoadingPop = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => Colors[props.activeUser].Light};
`;

const BackButton = styled.div`
  background-color: ${props => Colors[props.activeUser].Highlight};
  margin-left: 1rem;
  padding: 0.2rem 0.4rem;
  color: white;
  font-weight: 800;
`;

const LogOutButton = styled(BackButton)`
  margin-left: auto;
  margin-right: 1rem;
`;

const Chat = ({ mobile: isMobile, content, isLoading, activeUser, logOut }) => {
  const [chats, setChats] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [mobileDetail, setMobileDetail] = useState(false);
  const [{ showPhoto, photoSrc }, setPhotoPop] = useState({
    showPhoto: false,
    photoSrc: null
  });

  const scrollMessages = () => {
    const messageList = document.getElementById("message_list_container");
    if (messageList) {
      if (isMobile) {
        const mobileBottom = document.getElementById("mobile-bottom");
        if (mobileBottom) {
          mobileBottom.scrollIntoView(false);
        }
      } else {
        messageList.scrollTop = messageList.scrollHeight;
      }
    }
  };

  // const getChats = async () => {
  //   console.log("getting messages...");
  //   try {
  //     const chatData = await getChatData({ asUser: activeUser, content });
  //     setChats(chatData);
  //     scrollMessages();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    if (content) {
      setChats(content);
      scrollMessages();
    }
  }, [content]);

  useEffect(() => {
    scrollMessages();
  }, [activeIndex]);

  const chatList = chats && (
    <ChatListContainer activeUser={activeUser}>
      <ChatList
        className="chat-list"
        dataSource={chats.rooms.map((room, index) => {
          try {
            const lastChat =
              chats.messages[room.messages[room.messages.length - 1]];
            const oppositeUser =
              !room.isGroup && chats.users[room.oppositeUserID];
            return {
              avatar: room.isGroup ? room.groupIcon : oppositeUser.avatar,
              alt: room.isGroup ? room.description : oppositeUser.name,
              title: room.isGroup ? room.description : oppositeUser.name,
              subtitle: lastChat.body || "Sent Media",
              date: new Date(lastChat.date),
              unread: 0,
              className: activeIndex === index && "highlighted",
              index
            };
          } catch (error) {
            return {
              avatar: null,
              alt: "error loading chat",
              title: "error loading chat",
              subtitle: "error loading chat",
              date: new Date(),
              unread: 0,
              className: ""
            };
          }
        })}
        onClick={({ index }) => {
          setActiveIndex(index);
          setMobileDetail(true);
          if (isMobile && activeIndex === index) {
            setTimeout(() => {
              scrollMessages();
            }, 50);
          }
          const room = chats.rooms[index];
          const oppositeUser =
            !room.isGroup && chats.users[room.oppositeUserID];
          ga.event({
            category: `Chat | ${activeUser}`,
            action: "Selected Chat",
            label: room.isGroup ? room.description : oppositeUser.name,
            value: 4
          });

          // setTimeout(() => {
          //   setMobileDetail(true);
          // }, 100);
        }}
      />
    </ChatListContainer>
  );

  const messageList = chats && (
    <MessageHousingContainer>
      <MessageListContainer id="message_list_container" activeUser={activeUser}>
        <MessageList
          className="message-list"
          lockable={true}
          toBottomHeight={"100%"}
          onOpen={({ mainURL }) =>
            setPhotoPop({ showPhoto: true, photoSrc: mainURL })
          }
          dataSource={
            chats.rooms[activeIndex] &&
            chats.rooms[activeIndex].messages.map((messageID, index) => {
              try {
                const message = chats.messages[messageID];
                const { type, sender, date } = message;

                const isSender = UserID[activeUser] === sender;

                const messageData = {
                  index,
                  position: isSender ? "right" : "left",
                  date: new Date(date),
                  title: !isSender && chats.users[sender].name,
                  status: isSender && "sent"
                };

                switch (type) {
                  case ContentType.textMessage:
                    messageData.type = "text";
                    messageData.text = message.body;
                    break;
                  case ContentType.imageMessage:
                    messageData.type = "photo";
                    messageData.text = message.caption;
                    messageData.data = {
                      uri:
                        getAssetURL(message.image) +
                        "?fit=thumb&f=top_left&h=200&w=200"
                    };
                    messageData.mainURL = getAssetURL(message.image);
                    messageData.onDownload = () =>
                      console.log("download initiated");
                    break;
                  case ContentType.videoMessage:
                    messageData.type = "youTube";
                    messageData.data = {
                      videoId: message.youTubeId,
                      width: isMobile && 300,
                      height: isMobile && 220
                    };
                    break;
                  default:
                    break;
                }

                return messageData;
              } catch (error) {
                return {
                  index: 0,
                  position: "left",
                  date: new Date(),
                  title: "Error Loading Message",
                  type: "text",
                  text: "Error Loading Message"
                };
              }
            })
          }
        />
      </MessageListContainer>
      {!isMobile ? (
        <SendBar activeUser={activeUser}>
          <FakeInput placeholder="Type your message here" />
          <SendMessage>SEND</SendMessage>
        </SendBar>
      ) : (
        <div id="mobile-bottom" />
      )}
    </MessageHousingContainer>
  );

  return (
    <Div activeUser={activeUser} mobile={isMobile} mobileDetail={mobileDetail}>
      <Popup
        show={isLoading}
        renderContent={() => (
          <LoadingPop>
            <h1>Loading Messages</h1>
          </LoadingPop>
        )}
      />
      <Popup
        show={showPhoto}
        header="View Image"
        headerButtons={[
          {
            type: "transparent",
            color: "black",
            text: "close",
            onClick: () => setPhotoPop({ showPhoto: false, photoSrc })
          }
        ]}
        renderContent={() => <PhotoPop link={photoSrc} />}
      />
      {chats && (
        <>
          <TopBarContainer activeUser={activeUser}>
            {isMobile ? (
              <TopBarContacts activeUser={activeUser}>
                {mobileDetail && (
                  <BackButton
                    onClick={() => setMobileDetail(false)}
                    activeUser={activeUser}
                  >
                    &lt;&nbsp;BACK
                  </BackButton>
                )}
                <TopBarDescriptor>
                  {mobileDetail
                    ? chats.rooms[activeIndex] &&
                      chats.rooms[activeIndex].isGroup
                      ? `Group Chat: ${chats.rooms[activeIndex].description}`
                      : "Private Chat"
                    : `${getArtistName(activeUser)}'s Messages`}
                </TopBarDescriptor>
                {!mobileDetail && (
                  <LogOutButton
                    onClick={() => logOut()}
                    activeUser={activeUser}
                  >
                    Log Out
                  </LogOutButton>
                )}
              </TopBarContacts>
            ) : (
              <>
                <TopBarContacts activeUser={activeUser}>
                  <TopBarDescriptor>My Messages:</TopBarDescriptor>
                </TopBarContacts>
                <TopBarMessages>
                  <TopBarDescriptor>
                    {chats.rooms[activeIndex] &&
                    chats.rooms[activeIndex].isGroup
                      ? `Group Chat: ${chats.rooms[activeIndex].description}`
                      : "Private Chat"}
                  </TopBarDescriptor>
                </TopBarMessages>
              </>
            )}
          </TopBarContainer>

          <MainContainer showDetail={mobileDetail}>
            {isMobile ? (
              <>{mobileDetail ? messageList : chatList}</>
            ) : (
              <>
                {chatList}
                {messageList}
              </>
            )}
          </MainContainer>
        </>
      )}
    </Div>
  );
};

export default Chat;
