import {
  ContentType,
  UserID,
  RootImageFolder,
  RootFLFolder
} from "../constants/contentful";
// import { ARTIST } from '../constants/profiles';

const contentful = require("contentful");
const contentfulClient = contentful.createClient({
  space: process.env.REACT_APP_C_SPACE,
  accessToken: process.env.REACT_APP_C_KEY,
  host: process.env.REACT_APP_C_DOMAIN
});

const validateEntryFile = entry => entry && entry.fields && entry.fields.file;

const getAssetURL = entry => validateEntryFile(entry) && entry.fields.file.url;
const getAssetMIMEType = entry =>
  validateEntryFile(entry) && entry.fields.file.contentType;
const getAssetShortMIMEType = entry =>
  validateEntryFile(entry) && entry.fields.file.fileName.split(".")[1];

const getOppositeChatUser = (currentUserID, participantIDs) =>
  participantIDs.find(id => id !== currentUserID);

const breakoutFolderContents = ({ contents }) =>
  contents &&
  contents
    .map(({ sys, fields }) => {
      if (fields == null) {
        return null;
      }
      const itemType = sys.contentType.sys.id;
      if (itemType === ContentType.image) {
        return {
          type: ContentType.image,
          name: fields.fileName,
          url: getAssetURL(fields.imageRep),
          mime: getAssetMIMEType(fields.imageRep),
          ext: getAssetShortMIMEType(fields.imageRep)
        };
      }
      if (itemType === ContentType.imageFolder) {
        return {
          type: ContentType.imageFolder,
          name: fields.folderName,
          contents: breakoutFolderContents(fields)
        };
      }
      return null;
    })
    .filter(i => i != null);

const breakoutFLContents = ({ videos }) =>
  videos &&
  videos.map(({ fields }) => ({
    ...fields
  }));

const processEntries = (items, currentUser) => {
  /* Chat */
  const users = {};
  const messages = {};
  let rooms = [];
  // const assets = {};

  /* Image Library */
  const imageFolders = {};
  const imageFolderRefs = Object.values(RootImageFolder);
  imageFolderRefs.forEach(rootId => {
    imageFolders[rootId] = [];
  });

  /* FL Studio Sessions */
  const flFolders = {};
  const flFolderRefs = Object.values(RootFLFolder);
  flFolderRefs.forEach(rootId => {
    flFolders[rootId] = [];
  });

  /* Processing */
  const currentUserID = UserID[currentUser];

  items.forEach(({ sys, fields }) => {
    if (sys == null) {
      return;
    }
    const { id } = sys;
    const type = sys.contentType.sys.id;
    switch (type) {
      case ContentType.chatRoom:
        const { participants, isGroup } = fields;
        const participantIDs = participants.map(p => p.sys.id);
        if (participantIDs.includes(currentUserID)) {
          const oppositeUserID = getOppositeChatUser(
            currentUserID,
            participantIDs
          );
          if (fields.messages != null) {
            rooms.push({
              messages: fields.messages.map(message => message.sys.id),
              description: fields.description,
              isGroup,
              groupIcon: isGroup && getAssetURL(fields.groupIcon),
              oppositeUserID,
              participants
            });
          }
        }
        break;
      case ContentType.chatUser:
        users[id] = { ...fields, avatar: getAssetURL(fields.avatar) };
        break;
      case ContentType.textMessage:
      case ContentType.imageMessage:
      case ContentType.videoMessage:
        if (fields.sender == null) {
          return;
        }
        messages[id] = { ...fields, type, sender: fields.sender.sys.id };
        break;
      case ContentType.imageFolder:
        if (imageFolderRefs.includes(id)) {
          imageFolders[id] = breakoutFolderContents(fields);
        }
        break;
      case ContentType.flStudioLibrary:
        if (flFolderRefs.includes(id)) {
          flFolders[id] = breakoutFLContents(fields);
        }
        break;
      default:
      //
    }
  });

  rooms = rooms.map(room => {
    let newRoom = { ...room };
    newRoom.messages = newRoom.messages.filter(message => messages[message]);
    return newRoom;
  });

  rooms = rooms.sort((a, b) => {
    const aDate = messages[a.messages[a.messages.length - 1]].date;
    const bDate = messages[b.messages[b.messages.length - 1]].date;

    return new Date(bDate) - new Date(aDate);
  });
  console.log(JSON.stringify(flFolders));
  return {
    rooms,
    users,
    messages,
    imageFolders,
    flFolders
  };
};

const getChatData = async ({ content, asUser: currentUser }) => {
  return processEntries(content.items, currentUser);
};

const getImages = ({ content, asUser: currentUser }) => {
  if (content.imageFolders) {
    return content.imageFolders[RootImageFolder[currentUser]];
  }
  return [];
};

const fetchAllData = async () => contentfulClient.getEntries({ limit: 1000 });
const processDataForUser = ({ content, asUser: currentUser }) =>
  processEntries(content.items, currentUser);

export { getAssetURL, fetchAllData, processDataForUser, getImages };
