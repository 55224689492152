import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { format } from "timeago.js";
import ga from "react-ga";

import { RootFLFolder } from "../../constants/contentful";
import Colors from "../../themes/colors";
import { ARTIST } from "../../constants/profiles";

const buildYouTubeURL = ({ id, options }) =>
  `https://www.youtube.com/embed/${id}?${Object.entries(options)
    .map(([key, value]) => `${key}=${value}`)
    .join("&")}`;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const VideoList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  background-color: ${({ activeUser }) => Colors[activeUser].Black};
  overflow-y: scroll;
`;

const VideoLine = styled.div`
  width: 100%;
  padding: 1rem;
  border-bottom: 1px solid ${({ activeUser }) => Colors[activeUser].Highlight};
  background-color: ${({ activeUser, isActive }) =>
    isActive ? Colors[activeUser].Light : Colors[activeUser].Dark};
  ${({ isActive }) =>
    isActive &&
    css`
      box-shadow: inset 0px 0px 10px 0px rgba(255, 255, 255, 0.25);
    `}
  cursor: pointer;
  overflow-wrap: break-word;
  word-wrap: break-word;
  color: white;
  &:hover {
    background-color: ${({ activeUser, isActive }) =>
      isActive ? Colors[activeUser].Light : Colors[activeUser].Main};
  }
`;

const VideoLineTitle = styled.p`
  margin: 0;
  margin-bottom: 0.6rem;
  font-weight: 800;
`;

const VideoLineSubtitleTitle = styled.p`
  margin: 0;
  font-size: 12px;
`;

const VideoView = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 3;
  /* background-color: ${({ activeUser }) => Colors[activeUser].Black}; */
  background-color: #000;
`;

const FLStudio = ({ content, activeUser, isLoading }) => {
  const [selectedIndex, setSelected] = useState(0);
  const [activeSrc, setSrc] = useState(null);

  useEffect(() => {
    if (content.flFolders[RootFLFolder[activeUser]][selectedIndex]) {
      const { videoId: id, title } = content.flFolders[
        RootFLFolder[activeUser]
      ][selectedIndex];
      const src = buildYouTubeURL({
        id,
        options: {
          autoplay: 0,
          // origin: 'http://example.com',
          modestbranding: 1,
          iv_load_policy: 3,
          fs: 1,
          disablekb: 1
        }
      });
      setSrc(src);
      ga.event({
        category: `Video Library | ${activeUser}`,
        action: "Selected Video",
        label: title,
        value: 4
      });
    }
  }, [selectedIndex]);

  return (
    <Container>
      <VideoList activeUser={activeUser}>
        {content.flFolders[RootFLFolder[activeUser]] &&
          content.flFolders[RootFLFolder[activeUser]].map(
            ({ title, dateRecorded }, index) => (
              <VideoLine
                onClick={() => setSelected(index)}
                activeUser={activeUser}
                isActive={selectedIndex === index}
              >
                <VideoLineTitle>{title}</VideoLineTitle>
                <VideoLineSubtitleTitle>
                  {format(dateRecorded)}
                </VideoLineSubtitleTitle>
              </VideoLine>
            )
          )}
      </VideoList>
      <VideoView activeUser={activeUser}>
        <iframe
          // id="ytplayer"
          title="VideoLibraryVideo"
          type="text/html"
          width="100%"
          height="100%"
          src={activeSrc}
          frameborder="0"
        />
      </VideoView>
    </Container>
  );
};

export default FLStudio;
